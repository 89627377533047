function offset(el) {
    const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function verticalPosition(el) {
    const top = offset(el).top;
    const bottom = top + el.offsetHeight;
    return{top: top, bottom: bottom}
}

export { verticalPosition };

