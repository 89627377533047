import { verticalPosition } from './helpers';

const $mainLogo = document.getElementById('main-logo');
const $hamburger = document.getElementById('hamburger');
const $siteHeader = document.getElementById('site-header');
const colorElements = document.querySelectorAll(".bg-color-dark");
const colorPositions = [];


// Show the main logo on page load
$mainLogo.classList.add('active');

// BS IE PolyFill
//
// colorElements.forEach((item)=> {
//     colorPositions.push(verticalPosition(item));
// });
Array.prototype.forEach.call(colorElements, function(item) {
    colorPositions.push(verticalPosition(item));
});

if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }
function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function getCurrentBgColor(colorPositions, colorElements, scrollMarker) {
    // 1. Color Positions array of top and bottom values of elements that have
    // the data-color attribute set
    // 2. Color Elements that have the data-color attribute
    // 3. Scroll Marker is the position of the elment we are checking
    var currentBgColor = '';
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        for (var _iterator = colorPositions.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _step$value = _slicedToArray(_step.value, 2),
                index = _step$value[0],
                value = _step$value[1];

            if (scrollMarker > value.top && scrollMarker < value.bottom) {
                currentBgColor = colorElements[index].getAttribute('data-color');
                break;
            } else {
                currentBgColor = 'light';
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }

    return currentBgColor;
}


//
// LISTENERS
//

$hamburger.addEventListener('click', function() {
    this.classList.toggle('is-active');
    $siteHeader.classList.toggle('is-active');
    document.body.classList.toggle('hamburger-active');
});


window.addEventListener('scroll', function() {
    window.pageYOffset > 0 ? $mainLogo.classList.remove('active') : $mainLogo.classList.add('active');
    // Code below does not work in IE 11
    let burgerPosition = verticalPosition($siteHeader).bottom;
    const bgColor = getCurrentBgColor(colorPositions, colorElements, burgerPosition);
    document.body.setAttribute('data-bgColor', bgColor);
});
